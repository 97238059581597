import React from "react";
import styled from "styled-components";

import CustomBreadcrumbs from "../../components/common/CustomBreadcrumbs";
import Text from "../../components/common/Text";
import { titleHTML } from "../../helpers/title.helper";
import OnlineEvents from "./components/OnlineEvents";

const ForumSynchronous = () => {
  titleHTML("Foros especializados sincrónicos");

  return (
    <Container>
      <CustomBreadcrumbs />
      <Text fontSize="2rem" fontWeight="700" color="primary">
        Foros especializados sincrónicos
      </Text>
      <OnlineEvents />
    </Container>
  );
};

export default ForumSynchronous;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
`;
