import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';

// Components
import DocumentationTemplate from '../../../components/templates/academic/allStudents/DocumentationTemplate';
import Finance from '../../../components/ui/organisms/academic/Finance';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { financesActions } from '../../../redux/actions';

const Main = (props) => {
    const { handleSetStateFinance, handleSetStateDocumentation, tab } = props;

    // State
    const [wsGraduation, setwsGraduation] = useState(null);
    const [myWsGraduations, setmyWsGraduations] = useState(null);

    // REDUX
    const dispatch = useDispatch();
    const finances = useSelector((state) => state.finances.finances);
    const user = useSelector((state) => state.auth.user);

    // EFFECTS
    useEffect(() => {
        if (finances === null) {
            dispatch(financesActions.getFinancesRequest());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (myWsGraduations === null) {
            getWsGraduationById(user.partner_id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [myWsGraduations]);

    useEffect(() => {
        if (wsGraduation === null) {
            getWsGraduation();
        }
    }, [wsGraduation]);

    // Api Functions
    const getWsGraduationById = async (id) => {
        const result = await getWsGraduationByIdService(id);
        if (result !== false) {
            setmyWsGraduations(result);
        }
        return;
    };

    const getWsGraduation = async () => {
        const result = await getWsGradiationService();
        if (result !== false) {
            setwsGraduation(result);
        }
    };
    
    return (
        <MainContainer>
            {tab === 0 && (
                <DocumentationTemplate
                    handleSetStateDocumentation={handleSetStateDocumentation}
                />
            )}
            {tab === 1 && (
                <Container>
                    <Finance handleSetStateFinance={handleSetStateFinance} />
                </Container>
            )}
        </MainContainer>
    );
};

export default Main;

const URL = process.env.REACT_APP_SISAPI + '/v1/acropolis/graduation';

const getWsGraduationByIdService = async (id) => {
    const body = {
        partner_id: id,
    };
    try {
        const response = await axios.post(
            `${URL}/workshops_graduation_by_user`,
            body
        );
        return response.data.response_data;
    } catch (error) {
        console.error(error);
        return false;
    }
};

const getWsGradiationService = async () => {
    try {
        const response = await axios.get(`${URL}/get_ws_graduation`);
        return response.data.response_data;
    } catch (error) {
        console.error(error);
        return false;
    }
};

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
    border-radius: 5px;
    margin-top: 1rem;
`;

const Container = styled.div`
    background-color: #ffffff;
`;