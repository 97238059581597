import React from "react";
import styled from "styled-components";

const estados = [
  { id: 0, name: "Todos" },
  { id: 1, name: "En curso" },
  { id: 2, name: "Por iniciar" },
  { id: 3, name: "Finalizado" },
];

const FilterStudyPlan = (props) => {
  const { currentState, handleCurrentState } = props;

  // RETURN
  return (
    <CourseFiltersContainer>
      {estados.map((estado) => (
        <SpanWrapper
          key={estado.id}
          currentState={currentState === estado.name}
          onClick={() => handleCurrentState(estado.name)}
        >
          <Span currentState={currentState === estado.name}>
            {estado.name === "En curso" ? "En curso" : estado.name}
          </Span>
        </SpanWrapper>
      ))}
    </CourseFiltersContainer>
  );
};

export default FilterStudyPlan;

const CourseFiltersContainer = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 1.5rem;
  flex-wrap: wrap;
  padding-left: 4px;
  @media (max-width: 700px) {
        justify-content: center;
    }
`;

const SpanWrapper = styled.div`
  width: 150px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0.5rem 1rem;
  border-radius: 100px;
  color: ${(props) => (props.currentState ? "#b31d15" : "#616161")};
  background-color:#ffffff;
  cursor: pointer;
  box-shadow: ${(props) =>
    props.currentState
      ? "0px 3px 5px rgba(0, 0, 0, 0.15), 0px 5px 10px rgba(0, 0, 0, 0.06)"
      : "none"};
  
`;

const Span = styled.span`
  font-size: 14px;
  font-weight: 700;
`;
