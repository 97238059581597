import styled from "styled-components";
import { Text } from "../../../../components/common/Texts";
import { CheckCircle } from "@mui/icons-material";
import StateProgramChip from "../programs/StateProgramChip";

const MajorProgramCard = (props) => {

    const { program, index, programPlusInfo, setProgramToEnroll, setOpenModal, handleRedirect } = props;

    const getProgramState = () => {
        if (!program?.enrolled) {
            return "preinscripto"
        }
        if (program?.progress === 0) {
            return "confirmado"
        }
        if (program?.progress === 100) {
            return "egresado"
        }
        return "cursando"
    }

    return (
        <WrapperProgram>
            <StateWrapper>
                <Text
                    fontSize="16px"
                    color="#222222"
                    fontWeight="700"
                >
                    Estado:
                </Text>
                <StateProgramChip enrollmentState={getProgramState()} />
            </StateWrapper>
            <ModuleCheck
                checked={true}
            >
                <CheckCircle
                    fontSize={
                        "medium"
                    }
                />
                {programPlusInfo
                    ?.subprograms
                    .length -
                    1 !==
                    index && (
                        <Line
                            checked={
                                true
                            }
                        />
                    )}
            </ModuleCheck>
            <ProgramCardContainer>
                <Body>
                    <NameWrapper>
                        <Initials>
                            {
                                program?.initials_study_plan
                            }
                        </Initials>
                        <TitlesWrapper>
                            <Plan>
                                {
                                    program?.name
                                }
                            </Plan>
                        </TitlesWrapper>
                    </NameWrapper>
                    <ProgressWrapper>
                        <LinearProgressWrapper
                            second={
                                true
                            }
                        >
                            <LinearProgress
                                porcentaje={
                                    program
                                        ?.progress >
                                        100
                                        ? 100
                                        : program
                                            ?.progress
                                }
                            />
                        </LinearProgressWrapper>
                        <Percentage>
                            {program
                                ?.progress >
                                100
                                ? 100
                                : Math.round(program
                                    ?.progress)}
                            %
                        </Percentage>
                    </ProgressWrapper>
                    <RedirectButton
                        onClick={() => {
                            setProgramToEnroll(
                                program
                            );
                            if (
                                !program
                                    ?.enrolled
                            ) {
                                setOpenModal(
                                    true
                                );
                            } else {
                                handleRedirect(program?.id);
                            }
                        }}
                        variant="contained"
                    >
                        {
                            !program?.enrolled ?
                                "Matricularse" :
                                program?.progress === 0
                                    ? "Iniciar"
                                    : program?.progress === 100
                                        ? "Volver a ver"
                                        : "Continuar"}
                    </RedirectButton>
                </Body>
            </ProgramCardContainer>
        </WrapperProgram>
    )
}

export default MajorProgramCard;

const WrapperProgram = styled.div`
    position: relative;
    height: 10vh;
    width: 100%;
`;

const ProgramCardContainer = styled.div`
    display: flex;
    height: 101px;
    width: calc(100% - 5rem);
    margin-left: 5rem;
    gap: 1rem;
    position: relative;
    border-radius: 20px;
    background-color: #ffffff;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    cursor: ${(p) =>
        p.from === "sis" ? "pointer" : p.studyPlan ? "pointer" : "default"};

    :hover {
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

        :before {
            opacity: 1;
        }
    }

    @media (max-width: 768px) {
        height: 212px;
        width: 100%;
        margin-left: 0.5rem;
    }
`;

const Body = styled.div`
    box-sizing: border-box;
    display: flex;
    background-color: white;
    position: relative;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    gap: 1rem;
    padding: 1.5rem;
    z-index: 3;
    width: 100%;
    border-radius: 20px;

    @media (width <= 768px) {
        flex-direction: column;
    }
`;

const NameWrapper = styled.div`
    width: 40%;
    display: flex;
    align-items: center;
    gap: 1.5rem;

    @media (width <= 768px) {
        width: 100%;
    }
`;

const Initials = styled.div`
    width: 72px;
    height: 72px;
    min-width: 72px;
    border-radius: 50%;
    background-color: #b31d15;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    padding: 0.3rem;
    color: #fff;

    @media screen and (max-width: 768px) {
        border-radius: 50%;
        font-size: 15px;
        min-width: 50px;
        max-width: 50px;
        height: 50px;
    }
`;

const TitlesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
`;

const Plan = styled.h1`
    box-sizing: border-box;
    font-size: 20px;
    color: #222222;
    font-weight: 700;
    width: 100%;

    overflow: auto;

    ::-webkit-scrollbar {
        width: 1px;
    }
`;




const RedirectButton = styled.button`
    height: 40px;
    width: 15%;
    border-radius: 10px;
    background-color: #cd2118;
    color: #fff;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    margin-left: 4rem;

    :hover {
        background-color: #b31d15;
    }

    @media (width <= 768px) {
        width: 100%;
        margin-left: 0;
    }
`;

const StateWrapper = styled.div`
    position: absolute;
    /* top: -20px; */
    margin: auto;
    right: 0rem;
    top: -3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    @media (width <= 768px) {
        top: -3.5rem;

        div {
            display: none;
        }
    }
`;

const ModuleCheck = styled.div`
    position: absolute;
    left: 1rem;
    top: 45%;
    color: ${(props) => (props.checked ? "#b31d15" : "#A8A8A8")};
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    @media (width <= 768px) {
        top: -3.8rem;
        right: 22rem;

        svg {
            width: 35px;
            height: 35px;
        }
    }
`;

const Line = styled.div`
    width: 2px;
    background-color: ${(props) => (props.checked ? "#b31d15" : "#A8A8A8")};
    height: 140px;
    border-radius: 10px;

    @media (width <= 768px) {
        display: none;
    }
`;

const ProgressWrapper = styled.div`
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 0.5rem;

    @media (width <= 768px) {
        width: 100%;
    }
`;

const LinearProgressWrapper = styled.div`
    border-radius: 50px;
    width: 100%;
    height: 12px;
    background-color: ${(props) => (props.second ? "#f1f1f1" : "#fff")};
    padding: 0 3px;
    display: flex;
    align-items: center;
    justify-content: start;
`;

const LinearProgress = styled.div`
    border-radius: 50px;
    width: ${(props) => `${props.porcentaje}%`};
    height: 9px;
    background-color: #35d0a5;
`;

const Percentage = styled.span`
    font-size: 20px;
    color: #222;
    flex-wrap: wrap;
`;