import React from 'react';
import { SearchRounded } from '@mui/icons-material';
import { TextField, InputAdornment } from '@mui/material';
import styled from 'styled-components';

const DefaultSearch = (props) => {
    const { search, handleChange, label = 'Buscar', disabled = false } = props;

    return (
        <TextFieldFake
            value={search}
            onChange={handleChange}
            size="small"
            label=""
            variant="outlined"
            placeholder={label}
            disabled={disabled}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchRounded />
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default DefaultSearch;

const TextFieldFake = styled(TextField)`
    width: 400px;
    background-color: #ffffff;
    border-radius: 100px;
    margin-left: 4px;
    margin-bottom: 1rem;

    .MuiInputBase-root {
        color: #a8a8a8;
        font-weight: 500;
        border-radius: 100px;
    }
    fieldset {
        border: 1px solid #f9f9f9 !important;
        border-radius: 100px;
    }
    svg {
        fill: #616161;
        font-size: 20px;
        font-weight: 400;
    }

    :hover,
    :focus-within {
        outline: 1px solid #616161;
    }
    
`;
