// REACT
import React, { useState, useEffect, useReducer, useContext } from 'react';

// ROUTER
import { useHistory, useParams } from 'react-router-dom';

// BASE64
import * as encode from 'nodejs-base64-encode';

// COMPONENTS
import ModalAssignment from '../../../components/common/modals/ModalAssignment';
import ModalProblem from '../../../components/common/modals/ModalProblem';
import ModalPause from '../../../components/common/modals/ModalPause';
import ConfirmDialog from '../../ui/molecules/campus/DialogBlockDoc';
import NotificationChip from './chips/NotificationChip';
import StateSubjectContent from './wrappers/StateSubjectContent';
import StateSubjectsChip from './chips/StateChip';
import LazyImg from '../LazyImg';
import useSubjects from '../../../hooks/subject/useSubject';
import SubjectFooterButton from './buttons/SubjectFooterButton';
import reanudar_cursado from '../../../../src/assets/media/aden/reanudar_cursado.svg';
import curiosity_people from '../../../../src/assets/media/aden/curiosity-people.png';
import { MyProgressContext } from '../../../pages/progress/contexts/MyProgressProvider';
import {
    BodySubjectsCard,
    CourseStateChip,
    DisablerCover,
    Footer,
    LinearProgress,
    LinearProgressWrapper,
    Percentage,
    Plan,
    ProgressWrapper,
    SubjectCardContainer,
} from '../../../styled-components/subjects/subjectsCards';
import usePauseAssignment from '../../ui/molecules/studyPlan/hooks/usePauseAssignment';

const SubjectsCard = (props) => {
    const {
        data,
        setDataAssigment,
        dataAssigment,
        setIsChange,
        setIsFlag,
        isFlag,
        titulationState,
        statusStudentBlock,
    } = props;

    const { programId, assignments, programState, isDiplomat, repoId } =
        useContext(MyProgressContext);

    // ========= MODALS STATES =========
    const [openEnrollmentModal, setOpenEnrollmentModal] = useState(false);
    const [openModalToExpire, setOpenModalToExpire] = useState(false);
    const [openModalExpired, setOpenModalExpired] = useState(false);
    const [openModalBloqued, setOpenModalBloqued] = useState(false);
    const [hasCorrelatives, setHasCorrelatives] = useState(false);
    const [openModalTitulation, setOpenModalTitulation] = useState(false);
    const [openModalPause, setOpenModalPause] = useState(false);
    // const [open, setOpen] = useState(false);

    // ========= Prórrogas =========
    const extensionGranted = data?.cantidad_prorrogas > 0 ? true : false;
    const [openFirstExtensionRequestModal, setOpenFirstExtensionRequestModal] =
        useState(false);
    const [
        successFirstExtensionRequestModal,
        setSuccessFirstExtensionRequestModal,
    ] = useState(false);
    const [openNextExtensionRequestModal, setOpenNextExtensionRequestModal] =
        useState(false);

    const [state, subjectState] = useReducer(
        (prev, next) => {
            return { ...prev, ...next };
        },
        { subjectType: null, is_demo: false, is_elective: false }
    );

    // ========= VALIDATIONS =========
    const STATE_CASES = ['DEMO', 'confirmado', 'egresado'];
    const isNotCurrent = assignments?.assignment_without_information;

    // ========= ROUTER =========
    const history = useHistory();
    const { id } = useParams();

    //EFFECTS
    useEffect(() => {
        subjectTypeController();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        checkCorrelativityState(data?.correlative_subjects);
    }, [data]);

    // STATIC-DATA
    const hashedSubjectId = encode.encode(data.id + '', 'base64');

    // ========= HOOKS=========
    const {
        handleEnrollmentDates,
        enrollmentDates,
        progress,
        subjectCondition,
        imgGrey,
        PopulateNotificationChip,
    } = useSubjects(data, statusStudentBlock);

    const { error, postEnrollmentChange, newEnrollment } = usePauseAssignment();

    // ========= FUNCTIONS =========

    const checkCorrelativityState = (correlatives) => {
        if (!correlatives?.length) {
            return;
        }

        const blockSubject = correlatives?.filter((correlative) => {
            return !correlative.graduate;
        });

        setHasCorrelatives(blockSubject.length > 0);
    };

    /* Establece el tipo de asignatura dependiendo el estado que se le haya asignado en el repositorio*/
    const subjectTypeController = () => {
        if (programState === 'DEMO')
            subjectState({
                subjectType: 'DEMO',
                is_demo: !data?.cursado_demo,
                is_elective: false,
            });

        if (!data?.elective_subject && programState !== 'DEMO')
            subjectState({
                subjectType: 'ACTIVE',
                is_demo: false,
                is_elective: false,
            });
        handleEnrollmentDates(data?.enrollment_dates);
    };

    //Primer prórroga
    const handleFirstExtensionRequest = () => {
        setOpenModalExpired(false);
        setOpenFirstExtensionRequestModal(true);
    };
    const handleConfirmExtensionRequest = () => {
        const body = {
            partnerId: repoId,
            programId: programId,
            assignment: data?.id,
            state: data?.state,
            vencido: true,
        };
        postEnrollmentChange(body);
        setSuccessFirstExtensionRequestModal(true);
        setOpenFirstExtensionRequestModal(false);
    };

    //Segunda prórroga
    const handleNextExtensionRequest = () => {
        setOpenModalExpired(false);
        setOpenNextExtensionRequestModal(true);
    };

    const handleClick = () => {
        // inconvenientes con la documentación oficial de Maestría
        if (titulationState && data?.state === 'pendiente') {
            setOpenModalTitulation(true);
            return;
        }
        //Matriculación
        if (data?.state === 'pendiente') {
            setOpenEnrollmentModal(true);
            return;
        }
        //Retomar cursado
        if (data.state === 'preinscripto' || data.state === 'en_pausa') {
            setOpenModalPause(true);
            return;
        }
        //'DEMO', 'confirmado', 'egresado'
        if (STATE_CASES.includes(data?.state)) {
            history.push(
                `../asignatura/${hashedSubjectId}?program=${id}&origin=${
                    isDiplomat ? 'diplomado' : 'plan'
                }`
            );
            return;
        }
        // ! estado bloquedo pero progreso al 100%, ya sea con nota final o sin ella
        if (
            subjectCondition === 'Finalizado' ||
            subjectCondition === 'Egresado'
        ) {
            history.push(
                `../asignatura/${hashedSubjectId}?program=${id}&origin=plan`
            );
            return;
        }
    };

    const ToggleButtons = ({ type }) => {
        const cases = {
            demo: (
                <SubjectFooterButton
                    variant="contained"
                    disabled={
                        state.is_demo ||
                        (data.progress === 0 &&
                            statusStudentBlock?.studentStatus === 'Bloqueado')
                    }
                    onClick={state.is_demo ? () => {} : handleClick}
                    title={
                        state.is_demo
                            ? 'Bloqueado'
                            : data?.progress === 0
                              ? 'Comenzar'
                              : data?.progress === 100
                                ? 'Volver a ver'
                                : 'Continuar'
                    }
                />
            ),
            active: (
                <SubjectFooterButton
                    variant="contained"
                    disabled={
                        ((isNotCurrent ||
                            subjectCondition === 'Bloqueado' ||
                            subjectCondition === 'Vencido') &&
                            progress !== 100) ||
                        (progress === 0 && subjectCondition === 'Bloqueado') ||
                        hasCorrelatives
                    }
                    onClick={isNotCurrent ? () => {} : handleClick}
                    title={
                        subjectCondition === 'Sin matricular'
                            ? 'Matricular'
                            : subjectCondition === 'Por iniciar' ||
                                (subjectCondition === 'Próximo a vencer' &&
                                    progress === 0)
                              ? 'Iniciar'
                              : subjectCondition === 'En curso' ||
                                  (subjectCondition === 'Próximo a vencer' &&
                                      progress !== 0)
                                ? 'Continuar'
                                : subjectCondition === 'Finalizado' ||
                                    subjectCondition === 'Egresado'
                                  ? 'Volver a ver'
                                  : subjectCondition === 'En pausa'
                                    ? 'Retomar cursado'
                                    : `${subjectCondition}` //vencido o bloqueado
                    }
                />
            ),
        };

        return cases[type?.toLowerCase()] || cases['active'];
    };

    //Acciones del boton del footer

    const handleCloseModalPause = () => {
        setOpenModalPause(false);
    };

    const handleClickToSupport = () => {
        history.push('/support');
    };

    return (
        <>
            <SubjectCardContainer
                notAvailable={data.assignment_without_information}
                title={
                    data.progress === 0 &&
                    statusStudentBlock?.studentStatus === 'Bloqueado'
                        ? `Lamentablemente, no puedes realizar esta actividad en este momento. El acceso está temporalmente bloqueado debido a ${statusStudentBlock?.blockDetail?.reason}`
                        : data.name
                }
                demoUnlocker={data?.cursado_demo}
                isDemo={state.subjectType}
            >
                <CourseStateChip>
                    <StateSubjectsChip enrollmentState={subjectCondition} />
                </CourseStateChip>
                {!data?.cursado_demo && programState === 'DEMO' && (
                    <DisablerCover />
                )}
                <LazyImg
                    src={
                        !data.assignment_without_information
                            ? data.img
                            : '../../assets/aden/NotImageFound.png'
                    }
                    width="100%"
                    height="130px"
                    disabledState={imgGrey || hasCorrelatives}
                />
                {(data?.level ||
                    data?.rework_activities ||
                    data?.pending_correction) &&
                    PopulateNotificationChip?.length > 0 && (
                        <NotificationChip
                            content={PopulateNotificationChip(
                                data?.level,
                                data?.rework_activities,
                                data?.pending_correction
                            )}
                        />
                    )}
                <BodySubjectsCard>
                    {!data.assignment_without_information && (
                        <ProgressWrapper>
                            <LinearProgressWrapper>
                                <LinearProgress
                                    porcentaje={progress}
                                    disabledState={imgGrey}
                                />
                            </LinearProgressWrapper>
                            <Percentage disabledState={imgGrey}>
                                {progress}%
                            </Percentage>
                        </ProgressWrapper>
                    )}
                    <Plan
                        notAvailable={
                            data.assignment_without_information ||
                            subjectCondition === 'Bloqueado' ||
                            subjectCondition === 'Vencido' ||
                            subjectCondition === 'En pausa' ||
                            hasCorrelatives
                        }
                    >
                        <h2>{data.name || 'sin nombre'}</h2>
                    </Plan>
                    <StateSubjectContent
                        enrollmentState={subjectCondition}
                        data={data}
                        hasCorrelatives={hasCorrelatives}
                        setOpenModalExpired={setOpenModalExpired}
                        setOpenModalBloqued={setOpenModalBloqued}
                        setOpenModalToExpire={setOpenModalToExpire}
                        enrollmentDates={enrollmentDates}
                    />
                </BodySubjectsCard>
                <Footer>
                    <ToggleButtons
                        type={state?.subjectType}
                        disabled={!data?.subjects?.length}
                    />
                </Footer>
            </SubjectCardContainer>

            {/* ============================================= Modales ==================================== */}

            {/* ========= Matriculación ========= */}
            {openEnrollmentModal && (
                <ModalAssignment
                    isOpen={openEnrollmentModal}
                    handleCloseModal={() => setOpenEnrollmentModal(false)}
                    repo_id={repoId}
                    data={data}
                    programId={programId}
                    setDataAssigment={setDataAssigment}
                    dataAssigment={dataAssigment}
                    setIsChange={setIsChange}
                    setIsFlag={setIsFlag}
                    isFlag={isFlag}
                    isDiplomat={isDiplomat}
                />
            )}
            {/* ========= Reanudar cursado ========= */}
            {openModalPause && (
                <ModalPause
                    isOpen={openModalPause}
                    data={data}
                    programId={null}
                    hashedSubjectId={hashedSubjectId}
                    id={id}
                    isDiplomat={isDiplomat}
                    handleCloseModal={handleCloseModalPause}
                    imageModal={reanudar_cursado}
                />
            )}
            {/* ========= Vencido ========= */}
            {openModalExpired && (
                <ModalProblem
                    isOpen={openModalExpired}
                    title={'Asignatura vencida'}
                    description={
                        '<p>Lo sentimos,<strong> ya venció el plazo </strong> para cursar esta asignatura.</p>'
                    }
                    handleCloseModal={() => setOpenModalExpired(false)}
                    handleClick={
                        !extensionGranted //data?.cantidad_prorrogas === 0
                            ? handleFirstExtensionRequest
                            : handleNextExtensionRequest
                    }
                    isExpired={true}
                    data={data}
                    textButton={'Solicitar próroga'}
                />
            )}
            {/* ========= proximo a vencer ========= */}
            {openModalToExpire && (
                <ModalProblem
                    isOpen={openModalToExpire}
                    title={'Asignatura próxima a expirar'}
                    description={
                        '<p><strong>Esta asignatura expirará en las próximas semanas.</strong></p><p>Te sugerimos avanzar con los contenidos pendientes para asegurar la continuidad de tu cursado sin interrupciones.</p>'
                    }
                    handleCloseModal={() => setOpenModalToExpire(false)}
                    handleClick={handleClickToSupport}
                    isToExpire={true}
                    data={data}
                />
            )}
            {/* ========= bloqueado ========= */}
            {openModalBloqued && (
                <ModalProblem
                    isOpen={openModalBloqued}
                    title={'Asignatura bloqueada'}
                    description={
                        'Lo sentimos, esta asignatura se encuentra bloqueada.' /* por falta de pago.*/
                    }
                    handleCloseModal={() => setOpenModalBloqued(false)}
                    handleClick={handleClickToSupport}
                    imageModal={curiosity_people}
                />
            )}
            {/* === inconvenientes con la documentación oficial === */}
            {openModalTitulation && (
                <ConfirmDialog
                    handleCloseBlock={() => setOpenModalTitulation(false)}
                    openBlock={openModalTitulation}
                />
            )}
            {/* PRIMER PRORROGA  */}
            {openFirstExtensionRequestModal && (
                <ModalProblem
                    isOpen={openFirstExtensionRequestModal}
                    title={'1º Prórroga ¿deseas continuar?'}
                    description={
                        '<p> Ten en cuenta, <strong>que la primera prórroga es sin costo,</strong> luego deberás abonar las siguientes prórrogas para poder seguir cursando.</p>'
                    }
                    handleCloseModal={() =>
                        setOpenFirstExtensionRequestModal(false)
                    }
                    handleClick={handleConfirmExtensionRequest}
                    data={data}
                    imageModal={reanudar_cursado}
                    textButton={'Continuar'}
                />
            )}

            {successFirstExtensionRequestModal && (
                <ModalProblem
                    isOpen={successFirstExtensionRequestModal}
                    title={'¡Listo! Nuevo vencimiento'}
                    description={
                        '<p><strong>Extendimos el plazo para cursar esta asignatura.</strong> Aprovecha esta oportunidad para avanzar en tu formación y cumplir tus objetivos académicos.</p>'
                    }
                    handleCloseModal={() => {
                        setSuccessFirstExtensionRequestModal(false);
                        window.location.replace(window.location.href);
                    }}
                    handleClick={() => {
                        setSuccessFirstExtensionRequestModal(false);
                        window.location.replace(window.location.href);
                    }}
                    isExtended={true}
                    data={data}
                    textButton={'Entendido'}
                    newEnrollment={newEnrollment}
                    error={error}
                />
            )}

            {/* ======= SEGUNDA PRORROGA _ hacia soporte idem bloqueo ======= */}
            {openNextExtensionRequestModal && (
                <ModalProblem
                    isOpen={openNextExtensionRequestModal}
                    title={'2º Prórroga ¿deseas continuar?'}
                    description={
                        '<p>Ten en cuenta, que la primera prórroga fue sin costo, <strong> por lo tanto, ahora deberás abonar la siguiente prórroga para poder seguir cursando. </strong></p>'
                    }
                    handleCloseModal={() =>
                        setOpenNextExtensionRequestModal(false)
                    }
                    handleClick={handleClickToSupport}
                    data={data}
                    imageModal={reanudar_cursado}
                    textButton={'Continuar con mi Academic Advisor'}
                />
            )}
        </>
    );
};

export default SubjectsCard;
