import React from 'react';
import styled from 'styled-components';

const TabButton = (props) => {
    const { disabled, onClick, label, active } = props;

    return (
        <SubjectButton
            active={active}
            disabled={disabled}
            onClick={onClick}
        >
            {label}
        </SubjectButton>
    );
};

export default TabButton;

const SubjectButton = styled.button`
    cursor: pointer;
    min-width: 180px;
    width: fit-content;
    height: fit-content;
    padding: 0.5rem 1rem;
    margin-right: 1rem;
    border-radius: 100px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    background: #fff;
    color: ${({ active }) => active ? "#b31d15" : "#616161"} ;
    border: ${({ active }) => active && "1px solid #F1F1F1"};
    box-shadow: ${({ active }) => active && "0px 4px 4px -2px #18274B14, 0px 2px 4px -2px #18274B1F"};

`;
