import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";

// Components
import SimpleLoading from "../../components/common/SimpleLoading";
import CardInternationalWorkshop from "./CardInternationalWorkshop";

// Redux
import { useSelector } from "react-redux";

const Graduation = (props) => {
	const { workshops } = props;

	// State
	const [wsGraduation, setwsGraduation] = useState(null);
	const [myWsGraduations, setmyWsGraduations] = useState(null);

	// Redux
	const user = useSelector((state) => state.auth.user);

	// Effects
	useEffect(() => {
		if (myWsGraduations === null) {
			getWsGraduationById(user.partner_id);
		}
	}, [myWsGraduations, user]);

	useEffect(() => {
		if (wsGraduation === null) {
			getWsGraduation();
		}
	}, [wsGraduation]);

	// Api Functions
	const getWsGraduationById = async (id) => {
		const result = await getWsGraduationByIdService(id);
		if (result?.error?.response?.status === 404) {
			setmyWsGraduations([]);
		} else if (result.error) {
			setmyWsGraduations(false);
		} else {
			setmyWsGraduations(result);
		}
		return;
	};

	const getWsGraduation = async () => {
		const result = await getWsGradiationService();
		if (result !== false) {
			setwsGraduation(result);
		} else {
			setwsGraduation([]);
		}
	};

	const handleNewGetWs = () => {
		getWsGraduationById(user.partner_id);
	};

	// Renders

	if (myWsGraduations === null || wsGraduation === null) {
		return <SimpleLoading />;
	}

	return (
		<Container>
			<Body>
				{workshops.map((workshop) => (
					<CardInternationalWorkshop
						handleNewGetWs={handleNewGetWs}
						ws={wsGraduation}
						workshop={workshop}
						myWsGraduations={myWsGraduations}
						link={workshop.link}
					/>
				))}
			</Body>
		</Container>
	);
};

export default Graduation;

// API

const URL = process.env.REACT_APP_SISAPI + "/v1/acropolis/graduation";

const getWsGraduationByIdService = async (id) => {
	const body = {
		partner_id: id,
	};
	try {
		const response = await axios.post(
			`${URL}/workshops_graduation_by_user`,
			body
		);
		return response.data.response_data;
	} catch (error) {
		return {
			error,
		};
	}
};

const getWsGradiationService = async () => {
	try {
		const response = await axios.get(`${URL}/get_ws_graduation`);
		return response.data.response_data;
	} catch (error) {
		console.error(error);
		return false;
	}
};

const Container = styled.div`
	width: 100%;
	min-width: 350px;
	height: auto;
`;

const Body = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(385px, 1fr));
	justify-items: space-between;
`;
