import React, { useState } from 'react';
import styled from 'styled-components';
import { Card } from '@mui/material';
import { Text } from '../../../../components/common/Texts';
import { ScheduleRounded, Check } from '@mui/icons-material';

import moment from 'moment';
import { useSelector } from 'react-redux';
import RegisterEventModal from '../../../events/components/modals/RegisterEventModal';

const EventsCard = (props) => {
    const { event, countryName, getDynamicEvent, tabsFake } = props;

    const colorPrimary = useSelector(
        (state) => state?.ondemand?.ondemand?.primary_color
    );

    const city = useSelector((state) => state?.auth?.user?.city);

    // STATES
    const [openModal, setOpenModal] = useState(false);

    moment.updateLocale('es', {
        months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split(
            '_'
        ),
        monthsShort:
            'Enero._Feb._Mar_Abr._May_Jun_Jul._Ago_Sept._Oct._Nov._Dec.'.split(
                '_'
            ),
        weekdays: 'Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado'.split(
            '_'
        ),
        weekdaysShort: 'Dom._Lun._Mar._Mier._Jue._Vier._Sab.'.split('_'),
        weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_'),
    });

    const currentDate = new Date();
    const formatCurrentDate = moment(currentDate).locale('es').format('LL');

    const getLocalUTC = () => {
        const hours = new Date().getTimezoneOffset();
        if (hours > 0) {
            return `-${hours / 60}`;
        } else {
            return `+${(hours * -1) / 60}`;
        }
    };

    const utc = getLocalUTC();

    const eventDateBegin = new Date(
        event.date_begin.replaceAll('-', '/') + `${utc} UTC`
    );

    const monthNames = [
        'ENE',
        'FEB',
        'MAR',
        'ABR',
        'MAY',
        'JUN',
        'JUL',
        'AGO',
        'SEP',
        'OCT',
        'NOV',
        'DIC',
    ];

    // CAMBIA LA HORA SEGÚN LA LOCALIZACIÓN DEL USUARIO
    const ToggleLocationHour = (props) => {
        const { toggler } = props;
        const cases = {
            Argentina: (
                <Text fontSize="11px" color="#616161" component="span">
                    {event.hour_begin}
                    {' (AR) '}{' '}
                </Text>
            ),
            Panama: (
                <Text fontSize="11px" color="#616161" component="span">
                    {getHourNumber(event.hour_begin) -
                        2 +
                        ':' +
                        getMinuteNumber(event.hour_begin)}{' '}
                    {' (PA) '}{' '}
                </Text>
            ),
            'Costa Rica': (
                <Text fontSize="11px" color="#616161" component="span">
                    {getHourNumber(event.hour_begin) -
                        3 +
                        ':' +
                        getMinuteNumber(event.hour_begin)}{' '}
                    {' (CR) '}
                </Text>
            ),
        };

        return cases[toggler] || cases['Argentina'];
    };

    const formatEventDateBegin = moment(eventDateBegin)
        .locale('es')
        .format('LL');

    const getHourNumber = (hour) => {
        return parseInt(hour.substring(0, 2));
    };

    const getMinuteNumber = (hour) => {
        return hour.substring(3, 5);
    };

    const handleClick = () => {
        setOpenModal(!openModal);
    };

    return (
        <>
            {openModal && (
                <RegisterEventModal
                    event={event}
                    handleClick={handleClick}
                    getDynamicEvent={getDynamicEvent}
                    tabsFake={tabsFake}
                />
            )}
            <CardFake
                isRegistered={event.is_registered}
                colorPrimary={colorPrimary}
                type={event.type}
                onClick={handleClick}
            >
                <Body color="#fff" type={event.type}>
                    <TextFakeWrapper>
                        <TextFake
                            id="title"
                            fontSize="11px"
                            fontWeight="bold"
                            color="#222222"
                        >
                            {event.publication_name}
                        </TextFake>
                    </TextFakeWrapper>

                    <WrapperText>
                        <ScheduleRounded />
                        <ToggleLocationHour toggler={city} />
                    </WrapperText>
                    {event.type === 'PRESENCIAL' && (
                        <>
                            <WrapperText>
                                {/* <LocationOn /> */}
                                <Text
                                    fontSize="10px"
                                    color={colorPrimary || '#b31d15'}
                                    component="span"
                                >
                                    {countryName},{event?.location?.city},{' '}
                                    {event?.location?.street}
                                </Text>
                            </WrapperText>
                            {event.is_registered ? (
                                <WrapperInscription
                                    colorPrimary={colorPrimary}
                                    type={true}
                                >
                                    <Check /> Inscripto
                                </WrapperInscription>
                            ) : (
                                <WrapperInscription
                                    colorPrimary={colorPrimary}
                                    type={false}
                                >
                                    Inscribirme
                                </WrapperInscription>
                            )}
                        </>
                    )}
                    {event.type === 'ONLINE' &&
                        ((eventDateBegin === currentDate ||
                            formatEventDateBegin === formatCurrentDate) &&
                        event.is_registered ? (
                            <Footer>
                                <WrapperText>
                                    <CircleLive />
                                    <Text
                                        fontSize="11px"
                                        color="#b31d15"
                                        fontWeight="bold"
                                        component="span"
                                    >
                                        EN VIVO HOY
                                    </Text>
                                </WrapperText>
                                <DateWrapper>
                                    <p>
                                        {monthNames[eventDateBegin.getMonth()]}
                                    </p>
                                    <p>{eventDateBegin.getDate()}</p>
                                </DateWrapper>
                            </Footer>
                        ) : event.is_registered ? (
                            <Footer>
                                <WrapperInscription
                                    colorPrimary={colorPrimary}
                                    type={true}
                                >
                                    <Check /> Inscripto
                                </WrapperInscription>
                                <DateWrapper>
                                    <p>
                                        {monthNames[eventDateBegin.getMonth()]}
                                    </p>
                                    <p>{eventDateBegin.getDate()}</p>
                                </DateWrapper>
                            </Footer>
                        ) : (
                            <Footer>
                                <WrapperInscription
                                    colorPrimary={colorPrimary}
                                    type={false}
                                >
                                    Inscribirme
                                </WrapperInscription>
                                <DateWrapper>
                                    <p>
                                        {monthNames[eventDateBegin.getMonth()]}
                                    </p>
                                    <p>{eventDateBegin.getDate()}</p>
                                </DateWrapper>
                            </Footer>
                        ))}
                </Body>
            </CardFake>
        </>
    );
};

export default EventsCard;

const CardFake = styled(Card)`
    box-sizing: border-box;
    display: inline-flex;
    position: relative;
    align-items: center;
    gap: 2rem;
    min-height: ${(p) => (p.type === 'ONLINE' ? '142px' : '142px')};
    cursor: pointer;
    width: 100%;
    border-radius: 4px;
    box-shadow:
        0px 6px 12px -6px rgba(24, 39, 75, 0.12),
        0px 8px 24px -4px rgba(24, 39, 75, 0.08);
    scale: ${(p) => p.scale};

    :before {
        content: '';
        width: 5px;
        background-color: ${(p) =>
            !!p.isRegistered
                ? '#35d0a5'
                : p.colorPrimary
                  ? p.colorPrimary
                  : '#b31d15'};
        position: absolute;
        height: 100%;
        left: 0;
        z-index: 1;
    }
`;

const TextFakeWrapper = styled.div`
    height: auto;
    width: calc(100% - 45px);
`;

const TextFake = styled(Text)`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

const Body = styled.div`
    display: inline-flex;
    flex-direction: column;
    padding: 1rem;
    gap: ${({ type }) => (type === 'PRESENCIAL' ? '1rem' : '1.6rem')};
    position: relative;
    width: calc(100% - 25px);
    max-width: calc(100% - 25px);
    background: white;
    /* align-items: center; */
    align-items: ${(p) => (p.type === 'default' ? 'center' : 'flex-start')};
    transition: ease-in-out 0.3s all;

    :hover {
        background: linear-gradient(270deg, #ffe2e1 0%, #fff8f7 100%);
    }
`;

const WrapperText = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;

    svg {
        font-size: 16px;
        color: #a8a8a8;
    }
`;

const WrapperInscription = styled.div`
    background-color: ${(p) =>
        p.type ? '#35d0a5' : !!p.colorPrimary ? p.colorPrimary : '#b31d15'};
    border-radius: 5px;
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 0.8rem;
    padding: 0.2rem;
    svg {
        font-size: 1.1rem;
    }
`;

const CircleLive = styled.div`
    min-width: 12px;
    min-height: 12px;
    background-color: #b31d15;
    border-radius: 50%;
`;

const Footer = styled.div`
    display: flex;
    gap: 0.5rem;
    align-items: center;
`;

const DateWrapper = styled.div`
    display: flex;
    gap: 0.3rem;

    p {
        color: #222222;
        font-weight: 700;
        font-size: 0.9rem;
    }
`;
