import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import OptionWrapper from '../../ui/organisms/events/OptionWrapper';
import { Text } from '../../common/Texts';
import FontAwesomeIcon from '../../common/FontAwesomeIcon';

const AdenEvents = () => {
    // REDUX
    const history = useHistory();
    const colorPrimary = useSelector(
        (state) => state?.ondemand?.ondemand?.primary_color
    );

    // STATE
    const [currentTab, setCurrentTab] = useState(0);
    const [loading, setLoading] = useState(false);

    // FUNCTIONS
    function changeTab(e, value) {
        setCurrentTab(value);
    }

    return (
        <Container data-tut="reactour__proximos_eventos">
            <TitleWrapper>
                <Text color="#b31d15" fontWeight="700" fontSize="18px">
                    Próximos eventos
                </Text>
                <AllEventsButton
                    color="primary"
                    onClick={() => history.push('/eventos')}
                >
                    <FontAwesomeIcon
                        icon="fa-regular fa-calendar-day"
                        color="#B31D15"
                    />
                    <p>Ver todos</p>
                </AllEventsButton>
            </TitleWrapper>
            <NextWrapper>
                <MenuWrapper>
                    <ButtonFake
                        colorPrimary={colorPrimary}
                        onClick={() => changeTab(null, 0)}
                        active={currentTab === 0 ? true : false}
                        disabled={loading}
                    >
                        Virtuales
                    </ButtonFake>
                    <ButtonFake
                        colorPrimary={colorPrimary}
                        onClick={() => changeTab(null, 1)}
                        active={currentTab === 1 ? true : false}
                        disabled={loading}
                    >
                        Presenciales
                    </ButtonFake>
                </MenuWrapper>
                <OptionWrapper
                    currentTab={currentTab}
                    setLoading={setLoading}
                />
            </NextWrapper>
        </Container>
    );
};

export default AdenEvents;

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    background-color: none;
    gap: 25px;
    height: 100%;
    width: 100%;

    @media (max-width: 1136px) {
        width: 300px;
    }

    @media (max-width: 1024px) {
        width: 100%;
    }

    @media (max-width: 768px) {
        height: 765px;
        margin-top: 2.5rem;
    }
`;

const TitleWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    column-gap: 1rem;
    padding: 0 1.5rem;

    @media (max-width: 1260px) {
        padding: 0 1rem;

        p {
            font-size: 15px;
        }
    }
`;

const NextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    padding: 1.5rem;
    height: 100%;
    border-radius: 30px;
    background-color: #ffffff;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16),
        0px 3px 6px 0px rgba(0, 0, 0, 0.23);
`;

const MenuWrapper = styled.div`
    display: grid;
    gap: 12px;
    height: 50px;
    grid-template-columns: repeat(2, 1fr);
    padding: 0 18px;
`;

const ButtonFake = styled(Button)`
    width: 100%;
    border-radius: 20px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 14px;
    box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
        0px 8px 24px -4px rgba(24, 39, 75, 0.08);
    cursor: pointer;
    transition: all 0.3s ease;
    ${(p) =>
        !p.active &&
        `
            color: #cbcbcb;
            box-shadow: none;
        `}

    :hover {
        box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
            0px 8px 24px -4px rgba(24, 39, 75, 0.08);
    }
`;

const AllEventsButton = styled(Button)`
    display: flex;
    align-items: center;
    gap: 8px;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    right: 1rem;
    height: 40px;
    padding: 0 1rem;
    border-radius: 50px;
    background-color: #ffffff;

    &:hover {
        background-color: #f1f1f1;
    }

    p {
        margin-top: 4px;
    }

    svg {
        width: 20px;
        height: 20px;
        padding: 0;
        margin: 0;
    }

    @media (max-width: 1260px) {
        height: 30px;
        font-size: 12px;
        padding: 0 0.6rem;

        svg {
            width: 15px;
            height: 15px;
        }
    }
`;
