import { useContext } from 'react';
import { SummitContext } from '../../../../contexts';
import styled from 'styled-components';
import { Text } from '../../../../components/common/Texts';
import { QuestionAnswer, FmdGood } from '@mui/icons-material';
import { formatDate } from '../../../../utils/dates';
import SkeletonLoading from '../../../../components/common/SkeletonLoading';
import EventCard from '../../../events/components/EventCard';

const Conversation = () => {
    const { conversations } = useContext(SummitContext);

    if (!conversations) {
        return <SkeletonLoading height={500} width="100%" />;
    }

    return (
        <Wrapper>
            <Header>
                <TitleWrapper>
                    <QuestionAnswer />
                    <Text color="#ffffff" fontSize="24px" fontWeight="500">
                        Cumbre online
                    </Text>
                </TitleWrapper>
                <Subtext>
                    <FmdGood />
                    <Text color="#ffffff" fontSize="16px" fontWeight="400">
                        Evento online
                    </Text>
                </Subtext>
            </Header>
            <TextWrapper>
                {/* <Text color="#ffffff" fontSize="16px" fontWeight="400">
          Jornada presencial en sedes con abordaje de una temática particular.
          Estos conversatorios son optativos, por lo cual siéntete libre a la
          hora de elegir.
        </Text> */}
            </TextWrapper>
            {!conversations?.length ? (
                <NotFoundWrapper>
                    <Text color="#ffffff" fontSize="20px" fontWeight="700">
                        ¡Ups! No encontramos conversatorios en tu país.
                    </Text>
                </NotFoundWrapper>
            ) : (
                <Body>
                    {conversations?.map((conversation, i) => (
                        <EventCard
                            key={i}
                            value={{
                                date_begin: conversation?.date,
                                date_end: conversation?.date,
                                day: 'lunes',
                                month: 'abril',
                                image_url: conversation?.image,
                                category: { name: false },
                                location: {
                                    street: conversation?.eventLocation,
                                    city: conversation?.country,
                                },
                                crm_id: Number(conversation?.id),
                                publication_name: conversation?.title,
                                webinar_url: '',
                                is_registered: false,
                                registration_url: '',
                                description: conversation?.description,
                                hour_begin: conversation?.schedule,
                            }}
                        />
                    ))}
                </Body>
            )}
        </Wrapper>
    );
};

export default Conversation;

const Wrapper = styled.div`
    box-sizing: border-box;
    width: 100%;
    height: auto;
    padding: 2rem;
    border-radius: 20px;
    background-color: #b31d15;
    box-shadow:
        0px 0px 10px -0px rgba(0, 0, 0, 0.25),
        0px 12px 24px -15px rgba(0, 0, 0, 0.1);
`;

const Header = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &::after {
        content: ' ';
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: #ffffff;
        margin: auto;
        bottom: -20px;
    }
`;

const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.7rem;

    svg {
        color: #ffffff;
    }
`;

const Subtext = styled.div`
    display: flex;
    align-items: center;
    gap: 0.3rem;

    svg {
        width: 20px;
        height: 20px;
        color: #ffffff;
    }
`;

const TextWrapper = styled.div`
    margin-top: 2.5rem;
`;

const Body = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1.5rem;
    row-gap: 1.5rem;
    /* display: flex;
  flex-wrap: wrap;
  justify-content: space-between; */
    margin-top: 1.4rem;
`;

const NotFoundWrapper = styled.div`
    height: 200px;
    width: 100%;
    display: grid;
    place-items: center;
`;
